import { Footer, FooterAction, FooterCopyright, FooterNav } from '@components/Footer'
import Grid from '@components/Grid'
import { Nav, NavItem, NavLink } from '@components/Nav'
import Button from '@components/UI/Button'
import ButtonGroup from '@components/UI/ButtonGroup'
import Container from '@components/UI/Container'
import Link from '@components/UI/Link'
import Logo from '@components/UI/Logo'
import Heading from '@components/UI/Typography/Heading'
import VideoModal from '@components/VideoModal'
import useUtmTags from '@hooks/useUtmTags'
import css from '@utils/css'

import australianHeart from '@images/australianHeart.png'

import badge1 from '@images/badges-actual/badge1.svg'
import badge2 from '@images/badges-actual/badge2.svg'
import badge3 from '@images/badges-actual/badge3.svg'

import linkedin from '@images/linkedin.svg'
import React from 'react'

import Data from './data'

const Navigation = () => {
    return (
        <Footer>
            <Container className='flex flex-col gap-x-6 768:(flex-row)'>
                <FooterNav className='columns-2 gap-x-6 mb-10 768:(w-3/4 columns-3 mb-14)'>
                    { Data.map(block => (
                        <li
                            key={ block.id }
                            className={ 'flex break-inside-avoid flex-col not-last:pb-8 768:not-last:pb-12 gap-y-4' }
                        >
                            <Heading
                                tag='span'
                                className='h4'
                            >
                                { block.url? (
                                    <Link
                                        href={ block.url }
                                        linkProps={ { title:block.url_title } }
                                    >
                                        { block.heading }
                                    </Link>
                                ) : (
                                    block.heading
                                ) }
                            </Heading>

                            <Nav className='flex flex-col gap-y-4'>
                                { block.items.map(item => (
                                    <NavItem
                                        key={ item.id }
                                    >
                                        <NavLink
                                            href={ item.link }
                                        >
                                            { item.title }
                                        </NavLink>
                                    </NavItem>
                                )) }
                            </Nav>
                        </li>
                    )) }
                </FooterNav>

                <FooterAction className='768:w-1/4'>
                    <Link
                        href='/'
                        className='mb-6'
                    >
                        <Logo width={ 48 }/>
                    </Link>

                    <h3
                        className='mobile-heading-3 mb-6 768:desktop-heading-3'
                    >
                        Ready to get started?
                    </h3>

                    <ButtonGroup
                        direction='col'
                    >
                        <Button
                            tag='a'
                            type='link'
                            href={ useUtmTags(`${ process.env.NEXT_PUBLIC_APP_TRAQQ_URL }/signup`, { utm_medium: 'CTA_footer' }) }
                            variant='primary'
                            className='button_md'
                        >
                            Sign up
                        </Button>

                        <Button
                            tag='a'
                            type='link'
                            href={ useUtmTags('/demo', { utm_medium: 'CTA_footer' }) }
                            variant='outline_primary'
                            color='neutral'
                            className='button_md mb-6 whitespace-nowrap'
                        >
                            Book a demo
                        </Button>
                    </ButtonGroup>

                    <VideoModal
                        videoUrl={ 'https://www.youtube.com/embed/SNFKyh62lJo?si=XKjwUwtnin6Vsidk&autoplay=1' }
                        className={ 'button button_link justify-center mx-auto mb-6 !text-sm 768:ml-0 768:justify-start 768:text-left' }
                        btnText={ 'How Traqq works' }
                        iconSize={ 24 }
                    />

                    <div className='flex flex-col gap-y-0.5 mb-4'>
                        <span>
                            <strong className='desktop-subtitle'>Designed in Sydney, Australia</strong>
                            &nbsp;
                            { /* eslint-disable-next-line @next/next/no-img-element */ }
                            <img
                                src={ australianHeart.src }
                                alt='Traqq Australia'
                                width={ 24 }
                                height={ 24 }
                                className='inline'
                                loading='lazy'
                            />
                        </span>
                        <p className='desktop-body-4'>Level 10, 88 Phillip Street, Sydney NSW 2000. Traqq Pty Ltd.</p>
                    </div>

                    <Grid
                        xs={ 3 }
                        md={ 2 }
                        lg={ 3 }
                        gx={ 4 }
                        gy={ 4 }
                        className='mb-4 items-center'
                    >

                        { /* eslint-disable-next-line @next/next/no-img-element */ }
                        <img
                            src={ badge1 }
                            alt='G2 Easiest Admin'
                            width={ 78 }
                            height={ 102 }
                            loading='lazy'
                        />

                        { /* eslint-disable-next-line @next/next/no-img-element */ }
                        <img
                            src={ badge2 }
                            alt='G2 High Performer EMEA'
                            width={ 78 }
                            height={ 102 }
                            loading='lazy'
                            className={ css(
                                'w-[84px] -ml-[1px]'
                            ) }
                        />

                        { /* eslint-disable-next-line @next/next/no-img-element */ }
                        <img
                            src={ badge3 }
                            alt='G2 High Performer'
                            width={ 78 }
                            height={ 102 }
                            loading='lazy'
                        />

                    </Grid>
                </FooterAction>
            </Container>

            <FooterCopyright>
                <Container className='desktop-body-4 text-neutral-700 flex flex-col gap-y-1 768:(flex-row justify-between)'>
                    <div className={ css('flex flex-col gap-y-1 768:(flex-row-reverse gap-x-2)') }>
                        <Link
                            href='https://www.linkedin.com/company/traqq-time-tracking-software'
                            target='_blank'
                            className='flex items-center'
                        >
                            <img
                                src={ linkedin }
                                alt='Follow us on LinkedIn'
                                width={ 16 }
                                height={ 16 }
                            />
                            &nbsp;
                            <span>LinkedIn</span>
                        </Link>

                        Copyright 2019 - { new Date().getFullYear() } Traqq. All rights reserved.
                    </div>

                    <Nav
                        direction='row'
                    >
                        <NavItem>
                            <NavLink
                                href='/legal/privacy'
                                mods={ { neutral: 700 } }
                                className='!leading-5'
                            >
                                Privacy policy
                            </NavLink>
                            <span className='divider divider_solid divider_vertical'/>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href='/legal/terms'
                                mods={ { neutral: 700 } }
                                className='!leading-5'
                            >
                                Terms of use
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </FooterCopyright>
        </Footer>
    )
}

export default Navigation
